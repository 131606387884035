import React from "react";
import { Link } from "react-router-dom";
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import MenuBarHeader from "../menuBarHeader/MenuBarHeader";
import { FaWhatsapp } from 'react-icons/fa';
import { MdAlternateEmail } from 'react-icons/md';

import imgSec1 from "../media/hombre-metaverso.2f247ae29fb288f3d2ed.png";
import imgSec2 from "../media/matricula-ahora.png";
import imgSec4 from "../media/contacto.png";

import './Home.css';
import Footer from "../footer/Footer";

export default function Home() {
    React.useEffect(() => {
        let scrollToSet = 0;
        if (window.location.hash) {
            const target = document.getElementById(window.location.hash.substring(1));            
            if (target !== null) {
                scrollToSet = target.offsetTop;
            }
        }
        window.scrollTo({
            top: scrollToSet,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <React.Fragment>
            <MenuBarHeader />
            <div className="egl-body" style={{ paddingTop: '10vh' }}>
                <section
                    className="grid mx-0 py-4 md:py-8 px-2 md:px-8 egl-sec-home"
                    style={{ backgroundImage: 'linear-gradient(to right bottom, #363885, #313377, #2c2d6a, #27285d, #222350)' }}
                >
                    <div className="col-12 md:col-6 inline-flex md:hidden">
                        <div className="flex justify-content-center align-items-center">
                            <Image
                                src={imgSec1}
                                width="70%"
                                style={{ justifyContent: 'center', display: 'flex' }}
                            />
                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="flex flex-column justify-content-center align-items-center h-full">
                            <h1 className='text-xl md:text-5xl text-white uppercase mb-4' style={{ fontFamily: 'Zen Dots' }}>Bienvenid@ a EpicGroup Lab Valencia-Castellón</h1>
                            <p className="text-white text-lg mb-2 md:mb-6">Epicgroup Lab es una Ed-Tech nacida con el objetivo de llevar la educación sobre emprendimiento y tecnología al mercado de habla hispana en formato online, semipresencial y presencial.</p>
                            <Link to='/clase-demo' className='text-300 block no-underline mb-6'>
                                <Button label="SABER MÁS" className="mt-6 h-4rem bg-orange-500 border-orange-500" style={{ borderRadius: '50px', width: '250px' }} />
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 hidden md:inline-flex">
                        <div className="flex justify-content-end align-items-center w-full">
                            <Image
                                src={imgSec1}
                                width="100%"
                                style={{ justifyContent: 'center', display: 'flex' }}
                            />
                        </div>
                    </div>
                </section>
                <section className="grid mx-0 py-8 px-4 md:px-8 egl-sec-home">
                    <div className="col-12 md:col-6">
                        <div className="flex justify-content-start align-items-center w-full">
                            <Image
                                src={imgSec2}
                                width="100%"
                                style={{ justifyContent: 'center', display: 'flex' }}
                            />
                        </div>
                    </div>
                    <div className="col-12 md:col-6">
                        <div className="flex flex-column justify-content-center align-items-center h-full">
                            <h2 className='text-xl md:text-5xl text-indigo-800 uppercase mb-4' style={{ fontFamily: 'Zen Dots' }}>¡Matricúlate ahora!</h2>
                            <p className="text-indigo-800 text-lg mb-2 md:mb-6">Matricúlate en nuestros cursos online y empieza en camino hacia tu futuro como emprendedor. Aprende sobre emprendimiento y tecnología en el Metaverso y pertenece a la comunidad Epic.</p>
                            <Link to='/clase-demo' className='text-300 block no-underline mb-2'>
                                <Button label="SOLICITA UNA CLASE DEMO GRATIS" className="mt-6 h-4rem bg-orange-500 border-orange-500" style={{ borderRadius: '50px', width: 'auto' }} />
                            </Link>
                        </div>
                    </div>
                </section>
                <section
                    id="cursos"
                    className="grid mx-0 py-8 px-4 md:px-8"
                    style={{ backgroundImage: 'linear-gradient(to left top, #363885, #313377, #2c2d6a, #27285d, #222350)' }}
                >
                    <div className="col-12">
                        <div className="flex flex-column justify-content-center align-items-center h-full">
                            <h2 className='text-xl md:text-5xl text-white uppercase mb-4' style={{ fontFamily: 'Zen Dots' }}>Cursos disponibles</h2>
                            <p className="text-white text-lg mb-1 md:mb-3">En EpicGroup Lab Valencia-Castellón contamos con un curso de emprendimiento y otro de emprendimiento + tecnología.</p>
                            <p className="text-white text-lg mb-2 md:mb-6">Si te activas mediante el pago trimestral...¡No tendrás que pagar matrícula!</p>
                        </div>
                    </div>
                    <div className="col-12 grid mt-4 mx-0">
                        <div className="col-12 md:col-6 xl:col-3">
                            <div
                                className="flex flex-column justify-content-between align-items-center w-full p-4 egl-card-courses"
                                style={{
                                    background: '#f2f2f2',
                                    borderRadius: 25
                                }}
                            >
                                <h3 className='text-lg md:text-2xl text-orange-500 mb-4' style={{ fontFamily: 'Zen Dots' }}>Curso Emprendimiento Online</h3>
                                <p className="text-indigo-800 text-lg mb-1 md:mb-3">La matrícula te dará acceso anual a la comunidad y el pase VIP para eventos virtuales de Epic.</p>
                                <p className="text-indigo-800 text-lg mb-1 md:mb-3">El curso de emprendimiento online incluye las clases semanales de la comunidad.</p>
                                <Link to='/clase-demo' className='text-300 block no-underline'>
                                    <Button label="MATRICULARSE" className="mt-3 h-4rem bg-orange-500 border-orange-500" style={{ borderRadius: '50px', width: 'auto' }} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 xl:col-3">
                            <div
                                className="flex flex-column justify-content-between align-items-center w-full p-4 egl-card-courses"
                                style={{
                                    background: '#f2f2f2',
                                    borderRadius: 25
                                }}
                            >
                                <h3 className='text-lg md:text-2xl text-orange-500 mb-4' style={{ fontFamily: 'Zen Dots' }}>Curso Emprendimiento y Tecnología Online</h3>
                                <p className="text-indigo-800 text-lg mb-1 md:mb-3">La matrícula te dará acceso anual a la comunidad y el pase VIP para eventos virtuales de Epic.</p>
                                <p className="text-indigo-800 text-lg mb-1 md:mb-3">El curso de emprendimiento y tecnología online incluye las clases semanales de la comunidad.</p>
                                <Link to='/clase-demo' className='text-300 block no-underline'>
                                    <Button label="MATRICULARSE" className="mt-3 h-4rem bg-orange-500 border-orange-500" style={{ borderRadius: '50px', width: 'auto' }} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 xl:col-3">
                            <div
                                className="flex flex-column justify-content-between align-items-center w-full p-4 egl-card-courses"
                                style={{
                                    background: '#f2f2f2',
                                    borderRadius: 25
                                }}
                            >
                                <h3 className='text-lg md:text-2xl text-orange-500 mb-4' style={{ fontFamily: 'Zen Dots' }}>Curso Emprendimiento Online</h3>
                                <p className="text-indigo-800 text-lg mb-1 md:mb-3">El curso de emprendimiento online incluye una clase a la semana.</p>
                                <Link to='/clase-demo' className='text-300 block no-underline'>
                                    <Button label="MATRICULARSE" className="mt-3 h-4rem bg-orange-500 border-orange-500" style={{ borderRadius: '50px', width: 'auto' }} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 md:col-6 xl:col-3">
                            <div
                                className="flex flex-column justify-content-between align-items-center w-full p-4 egl-card-courses"
                                style={{
                                    background: '#f2f2f2',
                                    borderRadius: 25
                                }}
                            >
                                <h3 className='text-lg md:text-2xl text-orange-500 mb-4' style={{ fontFamily: 'Zen Dots' }}>Curso Emprendimiento y Tecnología Online</h3>
                                <p className="text-indigo-800 text-lg mb-1 md:mb-3">El curso de emprendimiento y tecnología online incluye las clases semanales de la comunidad.</p>
                                <Link to='/clase-demo' className='text-300 block no-underline'>
                                    <Button label="MATRICULARSE" className="mt-3 h-4rem bg-orange-500 border-orange-500" style={{ borderRadius: '50px', width: 'auto' }} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="contacto" className="grid mx-0 py-8 px-4 md:px-8 egl-sec-home">
                    <div className="col-12 md:col-6">
                        <div className="flex flex-column justify-content-center align-items-start h-full">
                            <p className="text-indigo-800 text-2xl mb-0">Si tienes cualquier pregunta...</p>
                            <h2 className='text-xl md:text-5xl text-indigo-800 uppercase mb-4' style={{ fontFamily: 'Zen Dots' }}>¡No dudes en contactar con nosotros!</h2>
                            <p className="text-indigo-800 text-lg mb-2 md:mb-6">¡Pronto te contactaremos desde el equipo Epic para resolver tus dudas y ayudarte!</p>
                            <div className="flex flex-column md:flex-row w-full justify-content-evenly align-items-center">
                                <Button
                                    label="+34 613-667-474"
                                    className="mt-6 h-4rem bg-orange-500 border-orange-500 px-5"
                                    style={{ borderRadius: '50px', width: '250px' }}
                                    onClick={() => window.location.href = 'https://wa.me/+34613667474'}
                                    icon={<FaWhatsapp size='1.5rem' />}
                                    iconPos='right'
                                    />
                                <Button
                                    label="info@epicgruop.es"
                                    className="mt-3 md:mt-6 h-4rem bg-orange-500 border-orange-500 px-5"
                                    style={{ borderRadius: '50px', width: '250px' }}
                                    onClick={() => window.location.href = 'mailto:info@epicgroup.es'}
                                    icon={<MdAlternateEmail size='1.5rem' />}
                                    iconPos='right'
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 md:col-6 hidden md:inline-flex">
                        <div className="flex justify-content-end align-items-center w-full">
                            <Image
                                src={imgSec4}
                                width="80%"
                                style={{ justifyContent: 'center', display: 'flex' }}
                            />
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </React.Fragment>
    );
}

