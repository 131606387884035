import React from "react";
import {
    BrowserRouter as Router,
    Route, Routes
} from "react-router-dom";
import { ScrollTop } from 'primereact/scrolltop';
import Error404 from "./error404/Error404";

import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "/node_modules/primeflex/primeflex.css";

import './App.css';
import Home from "./home/Home";
import ClaseDemo from "./claseDemo/ClaseDemo";
import LegalWarning from "./policy/LegalWarning";
import PrivacyPolicy from "./policy/PrivacyPolicy";
import CookiesPolicy from "./policy/CookiesPolicy";

function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/clase-demo" element={<ClaseDemo />} />
                <Route exact path="/aviso-legal" element={<LegalWarning />} />
                <Route exact path="/politica-privacidad" element={<PrivacyPolicy />} />
                <Route exact path="/politica-cookies" element={<CookiesPolicy />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
            <ScrollTop />
        </Router>
    );
}

export default App;