import React from "react";
import Footer from "../footer/Footer";
import MenuBarHeader from "../menuBarHeader/MenuBarHeader";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './style.css'

export default function CookiesPolicy() {
    const [descriptions] = React.useState([
        { name: 'ocn_accepted', desc: 'Cookie usada para recordar la aceptación de la política de privacidad por parte del usuario.', type: 'Técnica', expired: 'Persistente' },
        { name: '_ga, _gali, _gat, _gid', desc: 'Cookies de Google Analytics.', type: 'Analíticas, de terceros', expired: 'Persistente' },
        { name: 'ocn_accepted', desc: 'Cookie usada para recordar la aceptación de la política de privacidad por parte del usuario.', type: 'Analíticas, de terceros', expired: '_ga: 2 años' },
        { desc: 'Informes estadísticos sobre como los usuarios encuentran la página web y cómo la utilizan: páginas visitadas, tiempo de estancia, tipo de navegador…', expired: '_gid: 24 horas' },
        { desc: '_ga i _gid: Se usan para distinguir a los usuarios.', expired: '_gat: 10 minutos' },
        { desc: '_gat: Se usa para limitar el porcentaje de solicitudes', expired: '_gali: sesión' },
        { desc: 'Más información' },
        { name: 'player, vuid', desc: 'Cookies de Vimeo.', type: 'Personalización', expired: 'Persistente' },
        { desc: 'Se instalan al mostrar cualquier vídeo de Vimeo en una página.' }
    ]);

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <React.Fragment>
            <MenuBarHeader />
            <div className="egl-body" style={{ paddingTop: "9vh" }}>
                <section
                    className="mx-0 py-4 md:py-8 px-4 md:px-8 h-auto"
                    style={{ backgroundImage: "linear-gradient(to right bottom, #363885, #313377, #2c2d6a, #27285d, #222350)" }}
                >
                    <h2 className="text-xl md:text-5xl text-white uppercase mb-4" style={{ fontFamily: "Zen Dots" }}>Política de Cookies</h2>
                    <p className="text-white mb-2 md:mb-6">
                        En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de
                        la Sociedad de la Información y Comercio Electrónico le informamos
                        de que este Sitio Web, al igual que la mayoría de los sitios en
                        Internet, usa Cookies para mejorar y optimizar la experiencia del
                        usuario. A continuación encontrará información detallada sobre qué
                        son las “Cookies”, qué tipología utiliza este sitio web, cómo
                        cambiar la configuración de sus cookies, y qué ocurre si deshabilita
                        las Cookies.
                    </p>
                </section>
                <section className="mx-0 py-4 md:py-6 px-4 md:px-8 h-auto text-indigo-800">
                    <h2 className="text-indigo-800 text-lg md:text-3xl mb-4" style={{ fontFamily: "Zen Dots" }}>¿Qué son las cookies?</h2>
                    <p className="mb-1 md:mb-3">
                        Las cookies son archivos que se pueden descargar en su equipo a
                        través de las páginas web. Son herramientas que tienen un papel
                        esencial para la prestación de numerosos servicios de la sociedad de
                        la información. Entre otros, permiten a una página web almacenar y
                        recuperar información sobre los hábitos de navegación de un usuario
                        o de su equipo y, dependiendo de la información obtenida, se pueden
                        utilizar para reconocer al usuario y mejorar el servicio ofrecido.
                    </p>
                    <h2 className="text-indigo-800 text-lg md:text-3xl mb-4" style={{ fontFamily: "Zen Dots" }}>Tipos de cookies</h2>
                    <p className="mb-1 md:mb-3">
                        Según quien sea la entidad que gestione el dominio desde donde se
                        envían las cookies y trate los datos que se obtengan se pueden
                        distinguir dos tipos: cookies propias y cookies de terceros. Existe
                        también una segunda clasificación según el plazo de tiempo que
                        permanecen almacenadas en el navegador del cliente, pudiendo
                        tratarse de cookies de sesión o cookies persistentes. Por último,
                        existe otra clasificación con cinco tipos de cookies según la
                        finalidad para la que se traten los datos obtenidos: cookies
                        técnicas, cookies de personalización, cookies de análisis, cookies
                        publicitarias y cookies de publicidad comportamental. Para más
                        información a este respecto puede consultar la Guía sobre el uso de
                        las cookies de la Agencia Española de Protección de Datos.
                    </p>
                    <h2 className="text-indigo-800 text-lg md:text-3xl mb-4" style={{ fontFamily: "Zen Dots" }}>Cookies utilizadas en la Web</h2>
                    <div className="card">
                        <DataTable value={descriptions}
                            responsiveLayout="scroll">
                            <Column field="name" header="NOMBRE" className="text-indigo-800"></Column>
                            <Column field="desc" header="DESCRIPCIÓN/FINALIDAD" className="text-indigo-800"></Column>
                            <Column field="type" header="TIPO" className="text-indigo-800"></Column>
                            <Column field="expired" header="CADUCIDAD" className="text-indigo-800"></Column>
                        </DataTable>
                    </div>
                    <h2 className="text-indigo-800 text-lg md:text-3xl mb-4" style={{ fontFamily: "Zen Dots" }}>Cómo modificar la configuración de las Cookies</h2>
                    <p className="mb-1 md:mb-3">
                        Nacho GA asume que usted acepta el uso de cookies. No obstante,
                        usted puede restringir, bloquear o borrar las cookies de este Sitio
                        Web, o cualquier otra página, utilizando su navegador. En cada
                        navegador la operativa es diferente, la función de «Ayuda» le
                        mostrará cómo hacerlo.
                    </p>
                    <ul>
                        <li>Internet Explore</li>
                        <li>Safari</li>
                        <li>Chrome</li>
                        <li>Firefox</li>
                        <li>Opera</li>
                        <li>Edge</li>
                    </ul>
                    <p className="mb-1 md:mb-3">
                        Además, también puede gestionar el almacén de cookies en su
                        navegador a través de herramientas como las siguientes:
                    </p>
                    <ul>
                        <li>Ghostery</li>
                        <li>Your online choices</li>
                    </ul>
                    <h2 className="text-indigo-800 text-lg md:text-3xl mb-4" style={{ fontFamily: "Zen Dots" }}>Qué ocurre si se deshabilitan las Cookies</h2>
                    <p className="mb-1 md:mb-3">
                        Algunas funcionalidades de los servicios o áreas de este Sitio Web
                        podrían no funcionar correctamente.
                    </p>
                    <h2 className="text-indigo-800 text-lg md:text-3xl mb-4" style={{ fontFamily: "Zen Dots" }}>Actualización de la Política de Cookies</h2>
                    <p className="mb-1 md:mb-3">
                        Es posible que se actualice la política de cookies de la Página Web,
                        por ello, le recomiendo revisar esta política cada vez que acceda a
                        este Sitio Web con el objetivo de estar adecuadamente informado
                        sobre cómo y para qué se usan las cookies.
                    </p>
                </section>
            </div>
            <Footer />
        </React.Fragment>
    );
}
