import React from "react";
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import ReCAPTCHA from "react-google-recaptcha";
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isPostalCode from 'validator/lib/isPostalCode';
import { BsCheckAll } from "react-icons/bs";
import { RxExternalLink } from "react-icons/rx";
import MenuBarHeader from "../menuBarHeader/MenuBarHeader";
import Footer from "../footer/Footer";
import imgPerson from '../media/metaverse-person.svg';
import { Link } from "react-router-dom";
import { apiKudiExt } from "../utils/axios.kudi";

const locationsAllow = [
    { prefix: '+49', locale: 'de_DE', country: 'Alemania', countryCode: 'DE' },
    { prefix: '+376', locale: 'ca_AD', country: 'Andorra', countryCode: 'AD' },
    { prefix: '+34', locale: 'es-ES', country: 'España', countryCode: 'ES' },
    { prefix: '+1', locale: 'en-US', country: 'Estados Unidos', countryCode: 'US' },
    { prefix: '+33', locale: 'fr_FR', country: 'Francia', countryCode: 'FR' },
    { prefix: '+39', locale: 'it_IT', country: 'Italia', countryCode: 'IT' },
    { prefix: '+52', locale: 'es_MX', country: 'México', countryCode: 'MX' },
    { prefix: '+351', locale: 'pt_PT', country: 'Portugal', countryCode: 'PT' },
];

const iniDataSt = {
    client: '',
    country: 'es-ES',
    zipCode: '',
    phone: '',
    email: '',
    policy: false
};

const iniDataStValid = {
    client: { error: false, errorText: '' },
    country: { error: false, errorText: '' },
    zipCode: { error: false, errorText: '' },
    phone: { error: false, errorText: '' },
    email: { error: false, errorText: '' }
};

export default function ClaseDemo() {
    const [dataSt, setDataSt] = React.useState(iniDataSt);
    const [dataStValid, setDataStValid] = React.useState(iniDataStValid);
    const [loading, setLoading] = React.useState(false);
    const [send, setSend] = React.useState(false);

    const toastErr = React.useRef(null);
    const recaptchaRef = React.createRef();

    const handleClient = e => {
        if (e.target.value.length <= 50) {
            setDataStValid({
                ...dataStValid,
                client: { error: false, errorText: '' }
            });

            setDataSt({
                ...dataSt,
                client: e.target.value
            });
        }
    };

    const handleCountry = e => {
        setDataSt({
            ...dataSt,
            country: e.target.value
        });
    };

    const handleZipCode = e => {
        if (e.target.value.length <= 5) {
            setDataStValid({
                ...dataStValid,
                zipCode: { error: false, errorText: '' }
            });

            setDataSt({
                ...dataSt,
                zipCode: e.target.value
            });
        }
    };

    const handlePhone = e => {
        if (e.target.value.length <= 9) {
            setDataStValid({
                ...dataStValid,
                phone: { error: false, errorText: '' }
            });

            setDataSt({
                ...dataSt,
                phone: e.target.value
            });
        }
    };

    const handleEmail = e => {
        if (e.target.value.length <= 50) {
            setDataStValid({
                ...dataStValid,
                email: { error: false, errorText: '' }
            });

            setDataSt({
                ...dataSt,
                email: e.target.value
            });
        }
    };

    const handlePolicy = e => {
        setDataSt({
            ...dataSt,
            policy: e.checked
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        let valid = true;
        let dataValid = { ...dataStValid };

        if (dataStValid.client.error) {
            valid = false;
        } else if (dataSt.client === '') {
            valid = false;
            dataValid = {
                ...dataValid,
                client: { error: true, errorText: 'Dato obligatorio' }
            };
        }

        if (dataStValid.zipCode.error) {
            valid = false;
        } else if (dataSt.zipCode === '') {
            valid = false;
            dataValid = {
                ...dataValid,
                zipCode: { error: true, errorText: 'Dato obligatorio' }
            };
        } else if (!isPostalCode(dataSt.zipCode, locationsAllow.find(el => el.locale === dataSt.country).countryCode)) {
            valid = false;
            dataValid = {
                ...dataValid,
                zipCode: { error: true, errorText: 'Código postal incorrecto' }
            };
        }

        if (dataStValid.phone.error) {
            valid = false;
        } else if (dataSt.phone === '') {
            valid = false;
            dataValid = {
                ...dataValid,
                phone: { error: true, errorText: 'Dato obligatorio' }
            };
        } else if (!isMobilePhone(dataSt.phone, locationsAllow.find(el => el.locale === dataSt.country).locale)) {
            valid = false;
            dataValid = {
                ...dataValid,
                phone: { error: true, errorText: 'Teléfono móvil incorrecto' }
            };
        }

        if (dataStValid.email.error) {
            valid = false;
        } else if (dataSt.email === '') {
            valid = false;
            dataValid = {
                ...dataValid,
                email: { error: true, errorText: 'Dato obligatorio' }
            };
        } else if (!isEmail(dataSt.email)) {
            valid = false;
            dataValid = {
                ...dataValid,
                email: { error: true, errorText: 'Correo electrónico incorrecto' }
            };
        }

        if (valid) {
            recaptchaRef.current.executeAsync().then(oka => {
                apiKudiExt.post('/epic_groups/ext', {
                    'client': dataSt.client,
                    'phone': `${locationsAllow.find(el => el.locale === dataSt.country).prefix}${dataSt.phone}`,
                    'email': dataSt.email,
                    'country': locationsAllow.find(el => el.locale === dataSt.country).countryCode,
                    'zipCode': dataSt.zipCode
                }).then(response => {
                    setDataSt(iniDataSt);
                    setDataStValid(iniDataStValid);
                    setSend(true);
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    toastErr.current.show({
                        severity:'error', 
                        summary: 'Fallo en el envío', 
                        detail:'No se puedo establecer conexión con el servidor. Intente nuevamente.', 
                        life: 3000
                    });
                    setLoading(false);
                });
            }).catch(error => {
                console.log('ERROR!!!!!!!!!!!', error);
                toastErr.current.show({
                    severity:'error', 
                    summary: 'Fallo en el envío', 
                    detail:"Disculpa, no hemos podido establecer conexión con los servicios de Google API. Vuelva a intentarlo.", 
                    life: 3000
                });
                setLoading(false);
            });
        } else {
            setDataStValid({ ...dataValid });
            setLoading(false);
        }
    };

    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
      }, []);

    return (
        <React.Fragment>
            <MenuBarHeader />
            <div className="egl-body" style={{ paddingTop: '10vh' }}>
                <section
                    className="grid mx-0 py-4 md:py-6 px-2 md:px-8 h-auto"
                    style={{ backgroundImage: 'linear-gradient(to right bottom, #363885, #313377, #2c2d6a, #27285d, #222350)' }}
                >
                    {send ? (
                        <div className="col-12 md:col-6 h-full">
                            <h3 className='text-3xl md:text-5xl text-orange-500 mb-4' style={{ fontFamily: 'Zen Dots' }}>Solicita clase demo</h3>
                            <div className="flex flex-column text-white">
                                <p className="text-2xl fadein animation-duration-1000"><BsCheckAll className="text-4xl" /> Su solicitud ha sido enviada correctamente</p>
                                <p className="fadein animation-duration-1000">El equipo de Epic se pondrá en contacto contigo para ofrecerte los detalles para recibir tu Clase Demo.</p>
                                <p className="text-2xl uppercase fadein animation-duration-1000" style={{ fontFamily: 'Zen Dots' }}>¡Bienvenido! a EpicGroup Lab Valencia-Castellón</p>
                            </div>
                        </div>
                    ) : (
                        <div className="col-12 md:col-6 h-full">
                            <form className="flex flex-column justify-content-around align-items-center h-full" onSubmit={handleSubmit}>
                                <h3 className='text-3xl md:text-5xl text-orange-500 mb-4' style={{ fontFamily: 'Zen Dots' }}>Solicita clase demo</h3>
                                <div className="field w-full">
                                    <label htmlFor="client" className="block text-white pl-3">Nombre Completo</label>
                                    <InputText
                                        id="client"
                                        aria-describedby="client-help"
                                        className={`block w-full ${dataStValid.client.error ? "p-invalid border-3" : ""}`}
                                        style={{ borderRadius: 24 }}
                                        disabled={loading}
                                        value={dataSt.client}
                                        onChange={handleClient}
                                    />
                                    {dataStValid.client.error && (
                                        <small id="client-help" className="p-error block pl-3 w-full">{dataStValid.client.errorText}</small>
                                    )}
                                </div>
                                <div className="flex flex-column md:flex-row w-full justify-content-between mx-0">
                                    <div className="field w-full pr-0 md:pr-2">
                                        <label htmlFor="country" className="block text-white pl-3">País</label>
                                        <Dropdown
                                            value={dataSt.country}
                                            options={locationsAllow}
                                            optionLabel="country"
                                            optionValue="locale"
                                            onChange={handleCountry}
                                            className={`w-full ${dataStValid.country.error ? "p-invalid border-3" : ""}`}
                                            style={{ borderRadius: 24 }}
                                            disabled={loading}
                                        />
                                        {dataStValid.country.error && (
                                            <small id="zip-code-help" className="p-error block pl-3 w-full">{dataStValid.country.errorText}</small>
                                        )}
                                    </div>
                                    <div className="field w-full pl-0 md:pl-2">
                                        <label htmlFor="zip-code" className="block text-white pl-3">Código Postal</label>
                                        <InputText
                                            id="zip-code"
                                            aria-describedby="zip-code-help"
                                            className={`block w-full ${dataStValid.zipCode.error ? "p-invalid border-3" : ""}`}
                                            style={{ borderRadius: 24 }}
                                            disabled={loading}
                                            value={dataSt.zipCode}
                                            onChange={handleZipCode}
                                        />
                                        {dataStValid.zipCode.error && (
                                            <small id="zip-code-help" className="p-error block pl-3 w-full">{dataStValid.zipCode.errorText}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="field w-full">
                                    <label htmlFor="phone" className="block text-white pl-3">Teléfono Móvil</label>
                                    <div className="flex">
                                        <InputText
                                            value={locationsAllow.find(el => el.locale === dataSt.country).prefix}
                                            readOnly
                                            disabled={loading}
                                            style={{ borderRadius: '25px 0 0 25px' }}
                                            className="flex-none text-center w-2 min-w-min pl-2"
                                        />
                                        <InputText
                                            value={dataSt.phone}
                                            onChange={handlePhone}
                                            disabled={loading}
                                            type='number'
                                            className="flex-auto align-self-end pl-4 w-full"
                                            style={{ borderRadius: '0 25px 25px 0' }}
                                        />
                                    </div>
                                    {dataStValid.phone.error && (
                                        <small id="phone-help" className="p-error block pl-3 w-full">{dataStValid.phone.errorText}</small>
                                    )}
                                </div>
                                <div className="field w-full">
                                    <label htmlFor="email" className="block text-white pl-3">Correo Electrónico</label>
                                    <InputText
                                        id="email"
                                        aria-describedby="email-help"
                                        type='email'
                                        className={`block w-full ${dataStValid.email.error ? "p-invalid border-3" : ""}`}
                                        style={{ borderRadius: 24 }}
                                        disabled={loading}
                                        value={dataSt.email}
                                        onChange={handleEmail}
                                    />
                                    {dataStValid.email.error && (
                                        <small id="email-help" className="p-error block pl-3 w-full">{dataStValid.email.errorText}</small>
                                    )}
                                </div>
                                <div className="field-checkbox">
                                    <Checkbox 
                                        inputId="accept-policy"
                                        checked={dataSt.policy}
                                        onChange={handlePolicy}
                                    />
                                    <label htmlFor="accept-policy" className="text-white">Acepto las <Link to='/politica-privacidad' target="_blank" className='text-orange-500'>Política de Privacidad <RxExternalLink /></Link></label>
                                </div>
                                <Button
                                    label="Enviar"
                                    type="submit"
                                    className="bg-orange-500 border-orange-500 uppercase h-4rem mt-4 mb-6 md:mb-0"
                                    style={{ borderRadius: 50, width: '250px' }}
                                    loading={loading}
                                    disabled={!dataSt.policy}
                                />
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_KEY_RECAPTCHA}
                                    ref={recaptchaRef}
                                    size="invisible"
                                    style={{ borderRadius: 24 }}
                                    theme="dark"
                                    badge="bottomleft"
                                />
                            </form>
                        </div>
                    )}
                    <div className="col-12 md:col-6 hidden md:inline-flex">
                        <div className="flex justify-content-end align-items-center w-full">
                            <Image
                                src={imgPerson}
                                width="90%"
                                style={{ justifyContent: 'end', display: 'flex' }}
                                className="w-full"
                            />
                        </div>
                    </div>
                </section>
            </div>
            <Toast ref={toastErr} />
            <Footer />
        </React.Fragment>
    );
}