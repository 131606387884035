import React from "react";
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import { Link } from "react-router-dom";
import { FaTiktok, FaTwitter, FaFacebook, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import logo from "../media/Epic_imagotipo-black.png";

export default function Footer() {
    const handleLinkSeccHome = (e, mark) => {
        if (window.location.pathname === '/') {
            e.preventDefault();
            window.scrollTo({
                top: document.getElementById(mark).offsetTop,
                left: 0,
                behavior: 'smooth'
            });
        }
    };

    return (
        <footer className="grid mx-0 px-4 md:px-8 pt-4 pb-6" style={{ backgroundImage: "linear-gradient(to right bottom, #f97316, #db6818, #bd5d19, #a1511a, #85461a)" }}>
            <div className="col-12 md:col-4 mt-2">
                <h4
                    className='text-white text-3xl text-center uppercase'
                    style={{ fontFamily: 'Zen Dots' }}
                >Contáctanos</h4>
                <div className='flex justify-content-evenly w-full text-3xl my-4'>
                    <a href='https://www.tiktok.com/@epicgrouplab?is_from_webapp=1&sender_device=pc' target='_blank' className='text-white no-underline'><FaTiktok /></a>
                    <a href='https://twitter.com/EpickidsL?s=20&t=HLt-MWBeGZ3lpMN6DPyVpw' target='_blank' className='text-white no-underline'><FaTwitter /></a>
                    <a href='https://www.facebook.com/EpicGrouplab' target='_blank' className='text-white no-underline'><FaFacebook /></a>
                </div>
                <div className='flex justify-content-evenly w-full text-3xl'>
                    <a href='https://www.instagram.com/_epicgroup/' target='_blank' className='text-white no-underline'><FaInstagram /></a>
                    <a href='https://www.linkedin.com/company/epickids-lab/' target='_blank' className='text-white no-underline'><FaLinkedin /></a>
                    <a href='https://wa.me/+34613667474' target='_blank' className='text-white no-underline'><FaWhatsapp /></a>
                </div>
            </div>
            <div className="col-12 md:col-4 my-6 md:my-2">
                <div className="flex justify-content-around align-content-center h-15rem">
                    <div className='flex flex-column justify-content-evenly w-full text-3xl text-right'>
                        <Link to='/' className='text-white text-sm no-underline'>INICIO</Link>
                        <Link to='/clase-demo' className='text-white text-sm no-underline'>MATRICULA</Link>
                        <a href="/#cursos" onClick={ e => handleLinkSeccHome(e, 'cursos') } className='text-white text-sm no-underline'>CURSOS</a>
                        <a href="/#contacto" onClick={ e => handleLinkSeccHome(e, 'contacto') } className='text-white text-sm no-underline'>CONTACTO</a>
                    </div>
                    <div className='flex justify-content-evenly'>
                        <Divider layout="vertical" className="justify-content-cente mx-6" />
                    </div>
                    <div className='flex flex-column justify-content-evenly w-full text-3xl'>
                        <Link to='/aviso-legal' className='text-white text-sm no-underline'>Aviso Legal</Link>
                        <Link to='/politica-privacidad' className='text-white text-sm no-underline'>Política de Privacidad</Link>
                        <Link to='/politica-cookies' className='text-white text-sm no-underline'>Política de Cookies</Link>
                    </div>
                </div>
            </div>
            <div className="col-12 md:col-4 mt-2">
                <div className="flex flex-column justify-content-center h-4rem md:h-full">
                    <Image
                        src={logo}
                        width="80%"
                        style={{ justifyContent: 'center', display: 'flex' }}
                    />
                </div>
            </div>
        </footer>
    );
}