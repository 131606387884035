import React from "react";
import { Image } from 'primereact/image';
import vector404 from '../media/404-Not-Found.svg';
import MenuBarHeader from "../menuBarHeader/MenuBarHeader";
import Footer from "../footer/Footer";

export default function Error404() {
    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <React.Fragment>
            <MenuBarHeader />
            <section
                className='flex flex-column align-items-center justify-content-center mx-0 px-4 pt-8 h-screen surface-100'
                style={{ fontFamily: 'Zen Dots' }}
            >
                <div className="justify-content-center w-full md:w-6">
                    <Image
                        src={vector404}
                        alt="Not Found"
                        width="100%"
                    />
                </div>
                <h3 className="text-base text-center text-indigo-900">Lo sentimos, pero la página que solicita no existe. <a href="/">Regresar a inicio.</a></h3>
            </section>
            <Footer />
        </React.Fragment>
    );
}