import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import './MenuBarHeader.css';
import logo from "../media/Epic_imagotipo-black.png";

export default function MenuBarHeader() {
    const items = [
        { label: 'INICIO', url: '/' },
        { label: 'MATRICULA', url: '/clase-demo' },
        { label: 'CURSOS', url: '/#cursos' },
        { label: 'CONTACTO', url: '/#contacto' },
    ];

    const start = <img alt="logo" src={logo} height='70' className="mr-2" onClick={() => window.location.href = '/'}></img>;

    return (
        <Menubar
            className='w-full fixed z-5 shadow-8'
            start={start}
            model={items}
            style={{ background: '#FA4917', borderColor: '#FA4917', borderRadius: '0 0 12px 12px', height: 85 }}
        />
    );
}