import React from "react";
import Footer from "../footer/Footer";
import MenuBarHeader from "../menuBarHeader/MenuBarHeader";

export default function PrivacyPolicy() {
    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, []);

    return (
        <React.Fragment>
            <MenuBarHeader />
            <div className="egl-body" style={{ paddingTop: "9vh" }}>
                <section
                    className="mx-0 py-4 md:py-8 px-4 md:px-8 h-auto"
                    style={{ backgroundImage: "linear-gradient(to right bottom, #363885, #313377, #2c2d6a, #27285d, #222350)" }}
                >
                    <h2 className="text-xl md:text-5xl text-white uppercase mb-4" style={{ fontFamily: "Zen Dots" }}>Política de privacidad</h2>
                    <h2 className="text-lg md:text-3xl text-white mb-4" style={{ fontFamily: "Zen Dots" }}>¿Quién es el responsable del tratamiento de tus datos personales?</h2>
                    <p className="text-white mb-2 md:mb-6">
                        EPICKIDS LAB S.L. es el RESPONSABLE del tratamiento de los datos
                        personales del USUARIO y le informa que estos datos serán tratados
                        de conformidad con lo dispuesto en el Reglamento (UE) 2016/679 de 27
                        de abril (GDPR) y la Ley Orgánica 3/2018 de 5 de diciembre
                        (LOPDGDD).
                    </p>
                    <h2 className="text-lg md:text-3xl text-white mb-4" style={{ fontFamily: "Zen Dots" }}>¿Para qué tratamos tus datos personales?</h2>
                    <p className="text-white mb-2 md:mb-6">
                        1 Remisión de comunicaciones comerciales publicitarias por email,
                        fax, SMS, MMS, redes sociales sociales o cualquier otro medio
                        electrónico o físico, presente o futuro, que posibilite realizar
                        comunicaciones comerciales. Estas comunicaciones serán realizadas
                        por el RESPONSABLE y relacionadas sobre sus productos y servicios, o
                        de sus colaboradores o proveedores con los que éste haya alcanzado
                        algún acuerdo de promoción. En este caso, los terceros nunca tendrán
                        acceso a los datos personales. Realizar estudios de mercado y
                        análisis estadísticos. 2 Tramitar encargos, solicitudes, dar
                        respuestas a las consultas o cualquier tipo de petición que sea
                        realizada por el USUARIO a través de cualquiera de las formas de
                        contacto que se ponen a su disposición en la página web del
                        RESPONSABLE. 3 Remitir el boletín informativo online, sobre
                        novedades, ofertas y promociones en nuestra actividad.
                    </p>
                    <h2 className="text-lg md:text-3xl text-white mb-4" style={{ fontFamily: "Zen Dots" }}>¿Por qué motivo podemos tratar tus datos personales?</h2>
                    <p className="text-white mb-2 md:mb-6">
                        Porque el tratamiento está legitimado por el artículo 6 del GDPR de
                        la siguiente forma: 1 Por el consentimiento del USUARIO: remisión de
                        comunicaciones comerciales y el boletín informativo. 2 Por interés
                        legítimo del RESPONSABLE: realizar estudios de mercado, análisis
                        estadísticos, etc. y tramitar encargos, solicitudes, etc. a petición
                        del USUARIO.
                    </p>
                    <h2 className="text-lg md:text-3xl text-white mb-4" style={{ fontFamily: "Zen Dots" }}>¿Durante cuánto tiempo guardaremos tus datos personales?</h2>
                    <p className="text-white mb-2 md:mb-6">
                        Se conservarán durante no más tiempo del necesario para mantener el
                        fin del tratamiento o existan prescripciones legales que dictaminen
                        su custodia y cuando ya no sea necesario para ello, se suprimirán
                        con medidas de seguridad adecuadas para garantizar la anonimización
                        de los datos o la destrucción total de los mismos.
                    </p>
                    <h2 className="text-lg md:text-3xl text-white mb-4" style={{ fontFamily: "Zen Dots" }}>¿A quién facilitamos tus datos personales?</h2>
                    <p className="text-white mb-2 md:mb-6">
                        No está prevista ninguna comunicación de datos personales a terceros
                        salvo, si fuese necesario para el desarrollo y ejecución de las
                        finalidades del tratamientoen, a nuestros proveedores de servicios
                        relacionados con comunicaciones, con los cuales el RESPONSABLE tiene
                        suscritos los contratos de confidencialidad y de encargado de
                        tratamiento exigidos por la normativa vigente de privacidad.
                    </p>
                    <h2 className="text-lg md:text-3xl text-white mb-4" style={{ fontFamily: "Zen Dots" }}>¿Cuáles son tus derechos?</h2>
                    <p className="text-white mb-2 md:mb-6">
                        Los derechos que asisten al USUARIO son: 1 Derecho a retirar el
                        consentimiento en cualquier momento. 2 Derecho de acceso,
                        rectificación, portabilidad y supresión de sus datos y a la
                        limitación u oposición al su tratamiento. 3 Derecho a presentar una
                        reclamación ante la autoridad de control (www.aepd.es) si considera
                        que el tratamiento no se ajusta a la normativa vigente. Datos de
                        contacto para ejercer sus derechos: EPICKIDS LAB S.L. NIF: B90428715
                        Domicilio: CALLE DOCTOR RELIMPIO 6, 41003 – SEVILLA Teléfono: 692
                        599 391 Email: online@epickidslabs.com
                    </p>
                </section>
                <section className="mx-0 py-4 md:py-6 px-4 md:px-8 h-auto text-indigo-800">
                    <h2 className="text-indigo-800 text-lg md:text-3xl mb-4" style={{ fontFamily: "Zen Dots" }}>Consentimiento para el tratamiento de datos personales</h2>
                    <p className="mb-1 md:mb-3">
                        EPICKIDS LAB S.L. es el Responsable del tratamiento de los datos
                        personales del Usuario y le informa de que estos datos serán
                        tratados de conformidad con lo dispuesto en el Reglamento (UE)
                        2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de
                        diciembre (LOPDGDD), por lo que se le facilita la siguiente
                        información del tratamiento: Fines y legitimación del tratamiento:
                        mantener una relación comercial (por interés legítimo del
                        responsable, art. 6.1.f GDPR) y el envío de comunicaciones de
                        productos o servicios (por consentimiento del interesado, art. 6.1.a
                        GDPR). Criterios de conservación de los datos: se conservarán
                        durante no más tiempo del necesario para mantener el fin del
                        tratamiento o existan prescripciones legales que dictaminen su
                        custodia y cuando ya no sea necesario para ello, se suprimirán con
                        medidas de seguridad adecuadas para garantizar la anonimización de
                        los datos o la destrucción total de los mismos. Comunicación de los
                        datos: no se comunicarán los datos a terceros, salvo obligación
                        legal. Derechos que asisten al Usuario: 1 Derecho a retirar el
                        consentimiento en cualquier momento. 2 Derecho de acceso,
                        rectificación, portabilidad y supresión de sus datos y a la
                        limitación u oposición al su tratamiento. 3 Derecho a presentar una
                        reclamación ante la autoridad de control (www.aepd.es) si considera
                        que el tratamiento no se ajusta a la normativa vigente. Datos de
                        contacto para ejercer sus derechos:EPICKIDS LAB S.L. NIF: B90428715
                        Domicilio: CALLE DOCTOR RELIMPIO 6, 41003 – SEVILLA Teléfono: 692
                        599 391 Email: online@epickidslabs.com
                    </p>
                    <h2 className="text-indigo-800 text-lg md:text-3xl mb-4" style={{ fontFamily: "Zen Dots" }}>Consentimiento en la INSCRIPCIÓN A ACTIVIDADES</h2>
                    <p className="mb-1 md:mb-3">
                        PROTECCIÓN DE DATOS: De acuerdo con lo dispuesto en el Reglamento
                        (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5
                        de diciembre (LOPDGDD), consiento que mis datos se traten bajo la
                        responsabilidad de EPICKIDS LAB S.L. para enviarme información
                        comercial y que los conserve durante no más tiempo del necesario
                        para mantener el fin del tratamiento o mientras existan
                        prescripciones legales que dictaminen su custodia. Autorizo a que
                        comuniquen los datos a sus empresas asociadas para la misma
                        finalidad. Me doy por informado de que tengo derecho a revocar este
                        consentimiento en cualquier momento y a ejercer los derechos de
                        acceso, rectificación, portabilidad y supresión de mis datos, y los
                        de limitación y oposición al tratamiento dirigiéndome a CALLE DOCTOR
                        RELIMPIO, 6 - 41003 SEVILLA. Email: online@epickidslab.com. También
                        estoy informado de que puedo reclamar ante la autoridad de control a
                        www.aepd.es
                    </p>
                    <h2 className="text-indigo-800 text-lg md:text-3xl mb-4" style={{ fontFamily: "Zen Dots" }}>E-MAIL: correo electrónico y publicidad</h2>
                    <p className="mb-1 md:mb-3">
                        PROTECCIÓN DE DATOS: De conformidad con lo dispuesto en el
                        Reglamento (UE) 2016/679, de 27 de abril (GDPR), y la Ley Orgánica
                        3/2018, de 5 de diciembre (LOPDGDD), le informamos de que los datos
                        personales y la dirección de correo electrónico del interesado, se
                        tratarán bajo la responsabilidad de EPICKIDS LAB S.L. por un interés
                        legítimo y para el envío de comunicaciones sobre nuestros productos
                        y servicios, y se conservarán mientras ninguna de las partes se
                        oponga a ello. Los datos no se comunicarán a terceros, salvo
                        obligación legal. Le informamos de que puede ejercer los derechos de
                        acceso, rectificación, portabilidad y supresión de sus datos y los
                        de limitación y oposición a su tratamiento dirigiéndose a CALLE
                        DOCTOR RELIMPIO, 6 – 41003 SEVILLA. Email: online@epickidslab.com.
                        Si considera que el tratamiento no se ajusta a la normativa vigente,
                        podrá presentar una reclamación ante la autoridad de control en
                        www.aepd.es. PUBLICIDAD: En cumplimiento de lo previsto en el
                        artículo 21 de la Ley 34/2002 de Servicios de la Sociedad de la
                        Información y Comercio Electrónico (LSSICE), si usted no desea
                        recibir más información sobre nuestros productos y/o servicios,
                        puede darse de baja enviando un correo electrónico a
                        online@epickidslab.com, indicando en el Asunto «BAJA» o «NO ENVIAR».
                    </p>
                    <h2 className="text-indigo-800 text-lg md:text-3xl mb-4" style={{ fontFamily: "Zen Dots" }}>Consentimiento para el tratamiento de datos personales</h2>
                    <p className="mb-1 md:mb-3">
                        EPICKIDS LAB S.L. es el Responsable del tratamiento de los datos
                        personales del Usuario y le informa de que estos datos serán
                        tratados de conformidad con lo dispuesto en el Reglamento (UE)
                        2016/679, de 27 de abril (GDPR), y la Ley Orgánica 3/2018, de 5 de
                        diciembre (LOPDGDD), por lo que se le facilita la siguiente
                        información del tratamiento: Fines y legitimación del tratamiento:
                        mantener una relación comercial (por interés legítimo del
                        responsable, art. 6.1.f GDPR) y el envío de comunicaciones de
                        productos o servicios (por consentimiento del interesado, art. 6.1.a
                        GDPR). Criterios de conservación de los datos: se conservarán
                        durante no más tiempo del necesario para mantener el fin del
                        tratamiento o existan prescripciones legales que dictaminen su
                        custodia y cuando ya no sea necesario para ello, se suprimirán con
                        medidas de seguridad adecuadas para garantizar la anonimización de
                        los datos o la destrucción total de los mismos. Comunicación de los
                        datos: no se comunicarán los datos a terceros, salvo obligación
                        legal. Derechos que asisten al Usuario: 1 Derecho a retirar el
                        consentimiento en cualquier momento. 2 Derecho de acceso,
                        rectificación, portabilidad y supresión de sus datos y a la
                        limitación u oposición al su tratamiento. 3 Derecho a presentar una
                        reclamación ante la autoridad de control (www.aepd.es) si considera
                        que el tratamiento no se ajusta a la normativa vigente. Datos de
                        contacto para ejercer sus derechos: EPICKIDS LAB S.L. NIF: B90428715
                        Domicilio: CALLE DOCTOR RELIMPIO 6, 41003 – SEVILLA Teléfono: 692
                        599 391 Email: online@epickidslabs.com
                    </p>
                </section>
            </div>
            <Footer />
        </React.Fragment>
    );
}
